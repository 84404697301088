<template>
  <b-modal header-close-content="" :body-class="bg" :centered="true" @hide="handleHideEvent" v-model="modalState" :size="size" :id="id" hide-footer :title="title">
    <b-overlay :show="modalBusy">
      <slot></slot>
    </b-overlay>
  </b-modal>
</template>

<script>
  export default {
    name: "baseModal",
    props: {
      id: {
        type: String,
        default: () => 'base-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => ''
      },
      modalBusy: Boolean,
      size: {
        type: String,
        default: ()=> 'lg'
      },
      bg: {
        type: String,
        default: ()=> 'bg-white'
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    methods: {
      handleHideEvent(){
        this.$emit('input', false);
      },
    }
  }
</script>

<style>
  .modal-body.bg-grey, .bg-grey {
    background: #f7f8fa !important;
  }
</style>

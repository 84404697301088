<template>
  <portlet body-fluid :sticky="sticky" sticky-margin-top="140" sticky-z-index="200">
    <template v-slot:body>
      <div class="d-flex align-items-center justify-content-between w-100">
        <b-pagination
            v-if="meta"
            class="mb-0 mr-2"
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            @change="onChangePage"
        />
        <div v-if="implementSearch" class="kt-searchbar d-none d-sm-flex" :class="`w-${searchWidth}`">
          <div v-if="!hasSearchSlot" class="kt-input-icon kt-input-icon--left">
            <b-input @blur="refreshList" v-model="title" type="search" class="form-control" :placeholder="placeHolder" />
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                  </g>
                </svg></span>
            </span>
          </div>
          <slot name="search"></slot>
        </div>
        <div class="actions d-flex align-items-center">
          <button v-if="refreshable" @click="refreshList" v-b-tooltip.hover title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
            <i class="flaticon2-refresh-arrow"></i>
          </button>
          <slot name="action"></slot>
        </div>
      </div>
    </template>
    <template v-if="hasFootSlot" slot="foot">
      <slot name="foot"></slot>
    </template>
  </portlet>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import {debugConsole} from "@c@/helpers/helpers";
  export default {
    name: "Paginate",
    props: {
      meta: {
        type: Object
      },
      placeHolder: {
        type: String,
        default: ()=> 'ھالقىلىق سۆزنى كىرگۈزۈپ ئىزدەڭ'
      },
      sticky: {
        type: String,
        default: ()=> null
      },
      searchWidth: {
        type: Number,
        default: 25,
        validator: (value)=>{
          return [25, 50, 75].includes(value)
        }
      },
      implementSearch: {
        type: Boolean,
        default: ()=> true
      },
      refreshable: {
        type: Boolean,
        default: ()=> true
      }
    },
    components: { Portlet },
    computed: {
      hasFootSlot() {
        return !!this.$slots["foot"];
      },
      hasSearchSlot() {
        return !!this.$slots["search"];
      },
    },
    data(){
      return {
        title: '',
        page: 1
      };
    },
    methods: {
      refreshList(){
        let { title } = this;
        this.$emit('refresh', title);
      },
      onChangePage(page = 1){
        if ( page !== this.page ){
          this.$emit('change-page', {page, title: this.title});
          this.page = page;
        }
      },
    }
  }
</script>

<style scoped>

</style>

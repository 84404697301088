<template>
  <div class="user-list">
    <error :errors="userError" />
    <portlet fluidHeight>
      <template v-slot:body>
        <div class="kt-widget kt-widget--user-profile-3">
          <div class="kt-widget__top">
            <div class="kt-widget__media">
              <b-avatar :src="user.avatar" text="!" size="100px" rounded="" />
            </div>
            <div class="kt-widget__content">
              <div class="kt-widget__head">
                <div class="kt-widget__user">
                  <a href="javascript:;" class="kt-widget__username">
                    {{ user.nickname }}
                  </a>
                  <span class="kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-success cursor-default mx-1">ۋاكالەتچى</span>
                </div>
              </div>
              <div class="kt-widget__subhead">
                <a href="javascript:;" class="cursor-default" v-if="user.phone" v-b-tooltip title="تېلفۇن نۇمۇرى"><i class="fa fa-mobile"></i> {{ user.phone }} </a>
              </div>
              <div class="kt-widget__subhead" v-if="user.wallet">
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="ئۇمۇمى كىرىمى"><i class="fa fa-wallet"></i> {{ user.wallet.total || 0.00 }} ￥ </a>
                <a href="javascript:;" class="cursor-default" v-b-tooltip title="قالدۇق سوممىسى"><i class="fa fa-credit-card"></i> {{ user.wallet.remainder || 0.00 }} ￥ </a>
              </div>
              <!--<div class="kt-widget__info">
                <div class="kt-widget__desc"></div>
              </div>-->
            </div>
          </div>
          <div class="kt-widget__bottom">
            <div class="kt-widget__item">
              <div class="kt-widget__icon">
                <i class="flaticon2-group"></i>
              </div>
              <div class="kt-widget__details cursor-default">
                <span class="kt-widget__title">ئەكىرگەن ئەزاسى</span>
                <span class="kt-widget__value">{{ user.invitees_count }}<span>نەپەر</span></span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="user.profit_ratio">
              <div class="kt-widget__icon">
                <i class="flaticon-pie-chart"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`ئونىۋېرسال پايدا نىسپىتى`">
                <span class="kt-widget__title">پايدا نىسبىتى</span>
                <span class="kt-widget__value">{{ user.profit_ratio.ratio }}<span>%</span></span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="membershipReward" >
              <div class="kt-widget__icon" >
                <i class="flaticon-notepad"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`ئادەتتىكى VIP ئەزالىق پايدا نىسپىتى، پىرسەنت بويىچە پايدا ئايرىلىدۇ`">
                <span class="kt-widget__title">VIP ئەزالىق پايدا نىسپىتى</span>
                <span class="kt-widget__value">{{ membershipReward }} %</span>
              </div>
            </div>
            <div class="kt-widget__item" v-if="entrustReward.length">
              <div class="kt-widget__icon">
                <i class="flaticon-rotate"></i>
              </div>
              <div class="kt-widget__details cursor-default" v-b-tooltip :title="`كىلىشىم ئەزالىق پايدا سوممىسى، خالىغان بىر سوممىنى تاللاپ پايدا ئايرىلىدۇ`">
                <span class="kt-widget__title">كېلىشىم ئەزالىق پايدا سوممىسى</span>
                <span class="kt-widget__value">{{ entrustReward.map(item=> item.value).join('يۈەن، ') }} يۈەن</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </portlet>
    <b-overlay :show="formBusy">
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
                class="mb-0 mr-2"
                v-model="userWalletLogsMeta.current_page"
                :total-rows="userWalletLogsMeta.total"
                :per-page="userWalletLogsMeta.per_page"
                @change="onChangePage"
            />
            <div class="actions">
              <b-row class="align-items-center d-none d-sm-flex">
                <b-col md="5" sm="5" >
                  <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                </b-col>
                <b-col md="5" sm="5" >
                  <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
                </b-col>
                <b-col md="2" sm="2" >
                  <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                    يېڭىلاش
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
        <template slot="foot">
          <b-row v-if="enableList.includes('entrust_permission')" class="row-no-padding row-col-separator-lg">
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">كېلىشىم ئەزا</h4>
                    <span class="kt-widget24__desc">نورمال بار بولغىنى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ normal_contract_user || 0 }} نەپەر</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_contract_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ھازىرغىچە ئەكىرگەن كېلىشىم ئەزا</span>
                  <span class="kt-widget24__number">{{ all_contract_user || 0 }} نەپەر</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">نەقلەشتۈرگىنى</h4>
                    <span class="kt-widget24__desc">ھازىرغىچە نەقلەشتۈرگەن سومما</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ all_cashing || '0.00' }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_cashing_progress" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي كىرىم</span>
                  <span class="kt-widget24__number">{{ all_income || '0.00' }} يۈەن</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">باشقا كىرىم</h4>
                    <span class="kt-widget24__desc">كېلىشىم ئەزادىن سىرت باشقا كىرىم</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger">{{ except_contract_income || '0.00' }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="total_except_contract_progress" variant="danger"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي كىرىم</span>
                  <span class="kt-widget24__number">{{ all_income || '0.00' }} يۈەن</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col v-if="enableList.includes('entrust_permission')" sm="12" :md="enableList.includes('entrust_permission') ? 4 : 6">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">تارىخى كېلىشىم ئەزا</h3>
                    <span class="kt-widget1__desc">ھازىرغىچە ئەكىرگەن كېلىشىم ئەزا</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{ all_contract_user }}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">كېلىشىم ئەزا</h3>
                    <span class="kt-widget1__desc">ھازىر نورمال بار بولغان كېلىشىم ئەزا</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{ normal_contract_user }}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">مۇكاپات سوممىسى</h3>
                    <span class="kt-widget1__desc">كېلىشىم ئەزاغا بېرىلگەن مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{ contract_reward_income || '0.00'}}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
            <b-col sm="12" :md="enableList.includes('entrust_permission') ? 4 : 6">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">يېڭى ئەزا</h3>
                    <span class="kt-widget1__desc">يېڭى ئەزاغا بېرىلگەن مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{invitor_reward_income || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">فىلىم سېتىۋېلىش</h3>
                    <span class="kt-widget1__desc">ئايرىلغان پايدا سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{film_reward_income || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئادەتتىكى ئەزالىق</h3>
                    <span class="kt-widget1__desc">ئايرىلغان پايدا سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{membership_reward_income || '0.00'}}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
            <b-col sm="12" :md="enableList.includes('entrust_permission') ? 4 : 6">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئېلان</h3>
                    <span class="kt-widget1__desc">ئېلان چىكىش مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{ad_click_reward_income || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">نەقلەشتۈرگىنى</h3>
                    <span class="kt-widget1__desc">ھازىرغىچە نەقلەشتۈرگەن ئۇمۇمىي سومما</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{all_cashing || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">قالدۇق سومما</h3>
                    <span class="kt-widget1__desc">ھېساباتىدىكى نەقلەشتۈرۈشكە بولىدىغان قالدۇق سومما</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{ remainder }}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
          </b-row>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="log in userWalletLogs" md="3" :key="log.id" sm="6">
          <portlet body-class="position-relative" v-if="['film_episode', 'film', 'membership', 'entrust', 'entrust_out'].includes(log.logable_record)" title="" fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -10px">
                  <div class="kt-widget__media">
                    <b-avatar :src="log.logable ? log.logable.user.avatar : ''" size="50px" text="!" />
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable ? log.logable.user.nickname : 'نامەلۇم' }}
                    </a>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'film_episode'">سېتىۋالغان كىنودىن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'film'"> سېتىۋالغان كىنودىن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'membership'"> سېتىۋالغان ئەزالىقتىن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'entrust'">كېلىشىم ئەزا ئۈچۈن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'entrust_out'"> كېلىشىمدىن چېكىنگەنلىكى ئۈچۈن تۇتىۋېلىنغان پۇل</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label" v-if="log.record_type === 'income'"> ئايرىلغان پايدا </span>
                      <span class="kt-widget__label" v-if="log.record_type === 'payout'"> تۇتىۋېلىنغان پۇل </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label" > خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="log.record_type === 'payout'" class="kt-widget-corner-marker position-absolute overflow-hidden">
                <div class="kt-widget-corner-marker-wrapper position-absolute d-flex flex-column justify-content-center align-items-center">
<!--                  <i class="fab fa-creative-commons-nc-jp text-danger"></i>-->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3"/>
                      <path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#000000"/>
                    </g>
                  </svg>
                </div>
              </div>
            </template>
          </portlet>
          <portlet body-class="position-relative" v-if="['cashing'].includes(log.logable_record)" title="" fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -10px">
                  <div class="kt-widget__media">
                    <b-avatar size="50px" :src="log.logable.avatar" text="!" />
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable.nickname }} (ئۆزى)
                    </a>
                    <span class="kt-widget__desc">نەقلەشتۈرگەن چىقىم خاتىرىسى</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <!--                  <div class="kt-widget__section"></div>-->
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> نەقلەشتۈرۈلگەن سومما </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-widget-corner-marker position-absolute overflow-hidden">
                <div class="kt-widget-corner-marker-wrapper position-absolute d-flex flex-column justify-content-center align-items-center">
                  <i class="fa fa-yen-sign text-info"></i>
                </div>
              </div>
            </template>
          </portlet>
          <portlet body-class="position-relative" v-if="['click_ad', 'inviter'].includes(log.logable_record)" title="" fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -10px">
                  <div class="kt-widget__media">
                    <b-avatar size="50px" :src="log.logable? log.logable.avatar : ''" text="!" />
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable ? log.logable.nickname : 'نامەلۇم' }}
                    </a>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'click_ad'">ئېلان چەككەنلىكى ئۈچۈن ئايرىلغان پايدا</span>
                    <span class="kt-widget__desc" v-if="log.logable_record === 'inviter'">يېڭى ئەزا ئۈچۈن ئايرىلغان پايدا</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> ئايرىلغان پايدا </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
          <portlet body-class="position-relative" v-if="!log.logable_record" title="" fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -10px">
                  <div class="kt-widget__media">
                    <b-avatar size="50px" :src="log.logable? log.logable.avatar : ''" text="!" />
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ log.logable ? log.logable.nickname : 'نامەلۇم' }}
                    </a>
                    <span class="kt-widget__desc">{{ log.description }}</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> ئايرىلغان پايدا </span>
                      <span class="kt-widget__data">{{ log.count }}￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> خاتىرلەنگەن ۋاقتى </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ log.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!userWalletLogs.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، ھازىرچە ھىچقانداق مۇناسىۋەتلىك كىرىم - چىقىم ئۇچۇرى يوق ئىكەن." />
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import sweetMixin from "@m@/common/sweetAlert.mixin";
  import userMixin from "@m@/user.mixin";
  import walletMixin from "@m@/wallet.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";

  export default {
    name: "index",
    components: { Error, Portlet, Datetime, paginate, NoResult },
    mixins: [ sweetMixin, userMixin, formBusyMixin, walletMixin ],
    computed: {
      userId(){
        let {user_id} = this.$route.params;
        return user_id;
      },
      membershipReward(){
        if (this.user.hasOwnProperty('profit_ratio') && this.user.profit_ratio)
          return this.user.profit_ratio.membership_reward;
        return '';
      },
      entrustReward(){
        if (this.user.hasOwnProperty('profit_ratio') && this.user.profit_ratio)
          return this.user.profit_ratio.entrust_reward || [];
        return [];
      },
      all_income(){
        if ( this.user.wallet ){
          return this.user.wallet.total || '0.00';
        }
        return '0.00'
      },
      remainder(){
        if ( this.user.wallet ){
          return this.user.wallet.remainder || '0.00';
        }
        return '0.00'
      },
      all_contract_user(){
        return this.userWalletStatistics.all_contract_user;
      },
      normal_contract_user(){
        return this.userWalletStatistics.normal_contract_user;
      },
      total_contract_progress(){
        return (this.normal_contract_user / this.all_contract_user) * 100;
      },
      all_cashing(){
        return this.userWalletStatistics.all_cashing;
      },
      total_cashing_progress(){
        return (this.all_cashing / this.all_income) * 100;
      },
      except_contract_income(){
        return this.userWalletStatistics.except_contract_income;
      },
      total_except_contract_progress(){
        return (this.except_contract_income / this.all_income) * 100;
      },
      ad_click_reward_income(){
        return this.userWalletStatistics.ad_click_reward_income;
      },
      contract_reward_income(){
        return this.userWalletStatistics.contract_reward_income;
      },
      film_reward_income(){
        return this.userWalletStatistics.film_reward_income;
      },
      invitor_reward_income(){
        return this.userWalletStatistics.invitor_reward_income;
      },
      membership_reward_income(){
        return this.userWalletStatistics.membership_reward_income;
      },
    },
    created() {
      Promise.all([
        this.getUser(this.userId),
        this.getUserWalletLogs(this.userId, 1),
        this.getUserWalletStatistics(this.userId)
      ]);

    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئەزا باشقۇرۇش', route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({ title: "كىرىم چىقىم تەپسىلاتى" });
    },
    data(){
      return {
        formData: {
          start_time: null,
          end_time: null,
        },
        formBusy: false
      };
    },
    methods: {
      onChangePage(page = 1){
        this.getUserWalletLogs(this.userId, page, this.formData);
      },
      refreshList(){
        Promise.all([
          this.getUserWalletLogs(this.userId, 1, this.formData),
          this.getUserWalletStatistics(this.userId, this.formData)
        ])
      },
    }
  }
</script>


<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }

  .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content {
      padding-right: 1rem;
      padding-top: 0;
    }

    .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__subhead {
      padding: 0.5rem 0;
    }
  }

  .kt-widget-corner-marker, .kt-widget-corner-marker-wrapper {
    bottom: 0;
    right: 0;
    height: 100px;
    width: 100px;
  }

  .kt-widget-corner-marker-wrapper {
    bottom: -25px;
    right: -25px;
  }

  .kt-widget-corner-marker-wrapper i {
    font-size: 100px;
  }

  .kt-svg-icon {
    height: 100px;
    width: 100px;
  }

  .kt-widget-corner-marker-wrapper>* {
    opacity: .5;
  }


</style>
